<template>
  <router-view></router-view>
</template>
<script setup>
import { watch, reactive, onMounted, ref } from "vue";
import i18n from "@/i18n/i18n.js";
import useUserDataStore from "@/store/userDataStore";
import GlobalConfig from "@/config/config.js";
import { getCookie } from "@/utils/cookie.js";
import apiUser from "@/api/api/apiUser.js";
import router from "@/router/index.js";
import GlobalAudioPlayer from "@/components/media/audioPlayer/GlobalAudioPlayer.js";
import useHomeDataStore from "@/store/homeDataStore.js";
import useShopCartStore from "@/store/shopCartStore.js";
import useDraftDataStore from "@/store/create/draftDataStore.js";
import useLyricDataStore from "@/store/create/lyricDataStore.js";
import tools from "@/utils/tools.js";
import { $$language, $$t } from "@/i18n/i18n.js";
import message from "@/components/functionCallComponent/message/message.js";
import { funComponentList } from "@/components/functionComp/index";
import useCreateLayoutStore from "@/store/create/createLayoutStore.js";
import { isDev } from "@/utils/tools.js";

const createLayoutStore = useCreateLayoutStore();

const draftDataStore = useDraftDataStore();
const lyricDataStore = useLyricDataStore();

const showRouter = ref(false);

const userDataStore = useUserDataStore();
const shopCartStore = useShopCartStore();

let k_sso_token = getCookie("k_sso_token") ?? "";
const registCacheData = (_this) => {
  window.addEventListener("beforeunload", function (event) {
    const homeDataStore = useHomeDataStore();
    homeDataStore.abortSse();
    createLayoutStore.cacheLayoutData();
    if (
      tools.isProd() &&
      draftDataStore.lastGenerateDraftData?.lyrics != lyricDataStore.lyric &&
      !!lyricDataStore.lyric
    ) {
      const confirmationMessage =
        "Are you sure you want to exit? Ungenerated edits will be lost?";
      event.returnValue = confirmationMessage; // For most browsers
      return confirmationMessage; // For some older browsers
    }
  });
  window.addEventListener("load", function (event) {
    // 页面完全加载后执行的代码
    createLayoutStore.initCacheLayoutData();
    console.log("页面已加载");
  });
};

const codeReport = async () => {
  // if ($$language() == "cn") return
  const code = tools.getParameterByName("channel") ?? "";
  console.log("code", code);
  if (!code) return;
  const res = await apiUser.userCodePeport({ code: code });
  switch (res.code) {
    case 200:
      message.success({
        position: "top",
        content: $$t("common.saveSuccess"),
      });
      break;
    case 6318:
      message.error({
        maskClosable: true,
        position: "top",
        content: $$t("common.linkInvalid"),
      });
      break;
  }
};

const getQueryParameter = (name) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name);
};

onMounted(async () => {
  registCacheData();

  const cookie = getQueryParameter("auth_key") ?? "";
  if (cookie) {
    document.cookie = `skm_session${isDev() ? "_test" : ""}=${cookie};path=/`;
  }

  userDataStore.init();
  if (userDataStore.isLogin) {
    shopCartStore.getCartTotalCount();
  }

  codeReport();

  if (GlobalConfig.appname == "YinFeng") {
    document.body.classList.add("yinfeng");
  }
});
</script>
<style lang="scss">
.lottie-animation-contaniner {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  .lottie-animation-content {
    height: 80px;
    width: 80px;
  }
}
</style>
