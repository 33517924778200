import { createApp } from "vue"
import App from "./App.vue"
import router from "./router/index"
import store from "./store"
import setupDirective from "./lib/directive/directive"
import i18n from "./i18n/i18n.js"
// import VConsole from "vconsole"
import VueLazyload from "vue-lazyload"
import { isDev, getUrlAllParams } from "@/utils/tools.js"
import vue3GoogleLogin from "vue3-google-login"
import firebaseFuctionInit from "@/utils/analytic/firebaseUtils.js"
import fc from "@/components/functionComp/index"
import "@/assets/css/css.js"
import * as Sentry from "@sentry/vue"
import { firebaseUtils } from "@/utils/analytic/firebaseUtils.js"
import { $$language } from "@/i18n/i18n.js"


const { source, bd_vid } = getUrlAllParams(decodeURIComponent(location.href))
if (source) {
  window.sessionStorage.setItem(
    `ad_source`,
    JSON.stringify({
      source: source,
      bd_vid: bd_vid ?? "",
    })
  )
}

const env = import.meta.env.VITE_LANGUAGE ?? "en"
import layoutConfig, { initLayout } from "@/config/layoutConfig.js"
if (env == "cn") {
  var scriptElement = document.createElement("script")
  scriptElement.src = "https://hm.baidu.com/hm.js?ec88c725f3668b0e98d6f86f4850811a"
  var firstScript = document.getElementsByTagName("script")[0]
  firstScript.parentNode.insertBefore(scriptElement, firstScript)
} else {
  var script = document.createElement("script")
  script.src = "https://www.googletagmanager.com/gtag/js?id=G-17TCK1YGSV"
  script.async = true
  document.head.appendChild(script)

  script.onload = function () {
    window.dataLayer = window.dataLayer || []
    function gtag() {
      dataLayer.push(arguments)
    }
    gtag("js", new Date())
    gtag("config", "G-17TCK1YGSV")
  }
}

let app = null
// function loadRouter() {
//   console.log("layoutConfig.renderType", layoutConfig.renderType)
//   if (layoutConfig.renderType == "mobile") {
//     return import("./router/mobile.js")
//   } else {
//     return import("./router/index.js")
//   }
// }

// const ChageRouterList = ["/order-detail"];

// const isChangeRouter = () => {
//   const path = window.location.pathname;
//   return ChageRouterList.includes(path);
// };

// const pinia = createPinia();

// 以下注释部分稍后将恢复至全局只初始化一次，凡事涉及到移动端和PC端切换的，一切业务小范围内内部解决
// async function initializeApp() {
// 动态加载路由配置
// const { default: newRouter } = await loadRouter()

// if (app) {
//   app.unmount();
// }

const getSentryUrl = () => {
  let url = ""
  const language = $$language()
  if (isDev()) {
    if (language === "en") {
      url = "https://bb094092630245787fc6089de08dcb8b@sen-as.yinhe.top/7"
    } else {
      url = "https://806b960185aa65ce7d18071d56a0b739@sen-bj.yinhe.top/4"
    }
  } else {
    if (language === "en") {
      url = "https://6f7e9f113eab81487483f81773c1b9be@sen-as.yinhe.top/6"
    } else {
      url = "https://5e44e697fcf7373ca96a120cd99761e2@sen-bj.yinhe.top/2"
    }
  }
  return url
}

app = createApp(App)

Sentry.init({
  app,
  dsn: getSentryUrl(),
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

// app.use(pinia);
app.use(fc)
setupDirective(app)
app.use(router)
app.use(store)
app.use(i18n)
app.use(firebaseFuctionInit)
app.use(vue3GoogleLogin, {
  clientId: "1069642802272-0ktgtg6hn72ljht0bltd9h14qseflsvj.apps.googleusercontent.com",
  scope: "profile email",
})
app.use(VueLazyload)
app.mount("#app")
if (isDev()) {
  // window.__vConsole = new VConsole({ theme: 'dark' });
}
// }

const setSentryUid = async () => {
  const u_id = await firebaseUtils.getAnonymousID()
  Sentry.setUser({ id: u_id })
}

setSentryUid()

function onResize() {
  // 在窗口大小变化时重新加载路由
  // 设备没有变化，取消重新渲染
  // 普通页面不重新渲染
  const type = window.innerWidth > 768 ? "pc" : "mobile"
  if (type == layoutConfig.renderType) return
  initLayout()
  // if (!isChangeRouter()) return
  // initializeApp()
}

// 初始加载
// initializeApp();
window.addEventListener("resize", onResize)
